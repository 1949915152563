import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Card, List, DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
const { Content } = Layout;
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

// Components
import { Sidebar } from '../../Components/Sidebar/Sidebar';
import { HeaderBar } from '../../Components/Header/Header';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/CoreComponents/Loader';

// External Components
// import { Pie } from "@ant-design/charts";
import { Pie } from '@ant-design/plots';
// Network
import api from '../../Network/interceptor';
import { apiRoutes } from '../../Network/apiRoutes';

// Images
import images from '../../Assets/Images';
import './Dashboard.css';

// Utils
import withAuth from '../../Utils/wrapper';

const { RangePicker } = DatePicker;
interface Provider {
  title: string;
  logo: string;
}
interface Employer {
  title: string;
  href: string;
  id: number;
}
function Dashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [totalEinCounts, setTotalEinCounts] = useState(0);
  const [totalPlans, setTotalPlans] = useState(0);
  const [totalFilesSent, setTotalFilesSent] = useState(0);
  const [totalFilesReceived, setTotalFilesReceived] = useState(0);
  const [topProviders, setTopProviders] = useState<Provider[]>([]);
  const [employerRecord, setEmployerRecord] = useState([]);
  const [reMappedEmployers, setReMappedEmployers] = useState<Employer[]>([]);
  const [einAnalysisData, setEinAnalysisData] = useState<any>({});
  const [plansAnalysisData, setPlansAnalysisData] = useState<any>({});
  const [sentFilesAnalysisData, setSentFilesAnalysisData] = useState<any>({});
  const [receviedFilesAnalysisData, setReceviedFilesAnalysisData] =
    useState<any>({});
  const [employerRecordDateFilter, setEmployerRecordDateFilter] = useState<any>(
    { start: '', end: '' },
  );
  const [analysisDateFilter, setAnalysisDateFilter] = useState<any>({
    start: '',
    end: '',
  });
  async function fetchData() {
    try {
      const response = await api.get(apiRoutes.dashboard_count);
      setTotalEinCounts(response.data.data.TotalEin);
      setTotalPlans(response.data.data.TotalPlans);
      setTotalFilesSent(response.data.data.TotalFilesSent);
      setTotalFilesReceived(response.data.data.TotalFilesReceived);
      const providers: Provider[] = [];
      response.data.data.TopProviders.map((provider: any) => {
        providers.push({
          title: provider.Name,
          logo: provider.Logo,
        });
      });
      setTopProviders(providers);
      const employers: Employer[] = [];
      response.data.data.UnMappedEmployers.map((employer: any) => {
        employers.push({
          title: employer.LegalName,
          href: '',
          id: employer.ID,
        });
      });
      setReMappedEmployers(employers);
    } catch (response) {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        const record_response = await api.get(
          apiRoutes.dashboard_records +
            `?Start=${employerRecordDateFilter.start}&End=${employerRecordDateFilter.end}`
        );
        setEmployerRecord(record_response.data.data);
        setIsLoading(false);
      } catch (response) {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [employerRecordDateFilter]);

  useEffect(() => {
    async function fetchData() {
      try {
        const analytics_response = await api.get(
          apiRoutes.dashboard_analysis +
            `?Start=${analysisDateFilter.start}&End=${analysisDateFilter.end}`,
        );
        setEinAnalysisData(analytics_response.data.data.TotalEin);
        setPlansAnalysisData(analytics_response.data.data.TotalPlans);
        setSentFilesAnalysisData(analytics_response.data.data.TotalFilesSent);
        setReceviedFilesAnalysisData(
          analytics_response.data.data.TotalFilesReceived,
        );
      } catch (response) {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [analysisDateFilter]);

  const EmployerRecordconfig = {
    appendPadding: 5,
    data: employerRecord,
    color: ['#2A8BD5', '#F1A933', '#313E78'],
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    theme: 'default', // 'dark',
    innerRadius: 0.8,
    pieStyle: {
      lineWidth: 0,
    },
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 12,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
  };
  const EinAnalyticsconfig = {
    appendPadding: 0,
    pieStyle: {
      lineWidth: 0,
    },
    data: [einAnalysisData],
    width: 10,
    height: 200,
    color: '#4255D9',
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    legend: undefined,
    theme: 'default', // 'dark',
    innerRadius: 0.8,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 12,
      },
    },
    interactions: [
      {
        type: 'element-selected',
        enable: false,
      },
      {
        type: 'element-active',
      },
      { type: 'legend-filter', enable: false },
    ],
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          color: 'white',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: `${einAnalysisData.value}`,
      },
    },
  };
  const PlansAnalyticsconfig = {
    appendPadding: 0,
    pieStyle: {
      lineWidth: 0,
    },
    data: [plansAnalysisData],
    width: 10,
    color: '#F60390',
    height: 200,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    theme: 'default', // 'dark',
    legend: undefined,
    innerRadius: 0.8,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 12,
      },
    },
    interactions: [
      {
        type: 'element-selected',
        enable: false,
      },
      {
        type: 'element-active',
      },
      { type: 'legend-filter', enable: false },
    ],
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'white',
        },
        content: `${plansAnalysisData.value}`,
      },
    },
  };
  const SenFilesAnalyticsconfig = {
    appendPadding: 0,
    pieStyle: {
      lineWidth: 0,
    },
    data: [sentFilesAnalysisData],
    width: 10,
    height: 200,
    color: '#39C672',
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    theme: 'default', // 'dark','default'
    legend: undefined,
    innerRadius: 0.8,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 12,
      },
    },
    interactions: [
      {
        type: 'element-selected',
        enable: false,
      },
      {
        type: 'element-active',
      },
      { type: 'legend-filter', enable: false },
    ],
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'white',
        },
        content: `${sentFilesAnalysisData.value}`,
      },
    },
  };
  const RecivedFilesAnalyticsconfig = {
    appendPadding: 0,
    pieStyle: {
      lineWidth: 0,
    },
    data: [receviedFilesAnalysisData],
    width: 10,
    height: 200,
    color: '#F24A2F',
    angleField: 'value',
    colorField: 'type',
    legend: undefined,
    radius: 1,
    theme: 'default', // 'dark',"default"
    innerRadius: 0.8,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 12,
      },
    },
    interactions: [
      {
        type: 'element-selected',
        enable: false,
      },
      {
        type: 'element-active',
      },
      { type: 'legend-filter', enable: false },
    ],
    statistic: {
      title: undefined,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'white',
        },
        content: `${receviedFilesAnalysisData.value}`,
      },
    },
  };
  const onEmployerRecordRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
  ) => {
    if (dates) {
      setEmployerRecordDateFilter({
        start: dateStrings[0],
        end: dateStrings[1],
      });
    } else {
      setEmployerRecordDateFilter({ start: '', end: '' });
    }
  };
  const onAnalysisRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
  ) => {
    if (dates) {
      setAnalysisDateFilter({
        start: dateStrings[0],
        end: dateStrings[1],
      });
    } else {
      setAnalysisDateFilter({ start: '', end: '' });
    }
  };

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
  ];
  return (
    <>
      {isLoading && <Loader />}
      <Layout className="layout_wrapper">
        <HeaderBar activePopupLink="" />
        <Layout>
          <Sidebar currentVal="bureaus" />
          <Content>
            <div className="dashboard-data">
              <Row gutter={{ xs: 8, sm: 12, md: 16, lg: 16 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}>
                  <Card
                    className="color-card blue-card"
                    style={{ width: '100%' }}>
                    <div className="color-card-data">
                      <div className="color-left-data">
                        <p>Total EIN</p>
                        <h3>{totalEinCounts}</h3>
                      </div>
                      <div className="color-icon-box">
                        <img src={images.TotalEIn} alt="total EIn" />
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}>
                  <Card
                    className="color-card pink-card"
                    style={{ width: '100%' }}>
                    <div className="color-card-data">
                      <div className="color-left-data">
                        <p>Total Plans</p>
                        <h3>{totalPlans}</h3>
                      </div>
                      <div className="color-icon-box">
                        <img src={images.TotalPlan} alt="total EIn" />
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}>
                  <Card
                    className="color-card green-card"
                    style={{ width: '100%' }}>
                    <div className="color-card-data">
                      <div className="color-left-data">
                        <p>Total Files Sent</p>
                        <h3>{totalFilesSent}</h3>
                      </div>
                      <div className="color-icon-box">
                        <img src={images.CardArrowUp} alt="total EIn" />
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}>
                  <Card
                    className="color-card red-card"
                    style={{ width: '100%' }}>
                    <div className="color-card-data">
                      <div className="color-left-data">
                        <p>Total Files Received</p>
                        <h3>{totalFilesReceived}</h3>
                      </div>
                      <div className="color-icon-box">
                        <img src={images.CardArrowDown} alt="total EIn" />
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 12, md: 16, lg: 16 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}>
                  <Card
                    title="Recently UnMapped Employers"
                    style={{ width: '100%' }}
                    extra={
                      <a
                        className="unmapped_all_link"
                        onClick={() => {
                          navigate('/employers/?status=Un-Mapped');
                        }}>
                        Show All
                      </a>
                    }
                    className="dashboard-card">
                    <List
                      itemLayout="horizontal"
                      dataSource={reMappedEmployers}
                      renderItem={(item) => (
                        <List.Item
                          key={item.id}
                          actions={[
                            <a href={`/mapping/${item.id}`} key={item.id}>
                              <img src={images.ArrowRight} alt="arrow-right" />
                            </a>,
                          ]}>
                          <List.Item.Meta key={item.href} title={item.title} />
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}>
                  <Card
                    title="Top Provider List"
                    style={{ width: '100%' }}
                    className="dashboard-card">
                    <List
                      className="provider-list"
                      itemLayout="horizontal"
                      dataSource={topProviders}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            title={item.title}
                            avatar=<img src={item.logo && item.logo.trim() !== "" ? item.logo : images.PlaceholderImage} alt= "Provider-logo" />
                          />
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 12, md: 16, lg: 16 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 10 }}>
                  <Card
                    title="Employer Record"
                    extra={
                      <>
                        <RangePicker
                          onChange={onEmployerRecordRangeChange}
                          presets={rangePresets}
                          className="custom-datepicker"
                          // disabledDate={disabledDate}
                          bordered={false}
                        />
                      </>
                    }
                    style={{ width: '100%' }}
                    className="dashboard-card">
                    <div className="chart-div">
                      <Pie {...EmployerRecordconfig} />
                    </div>
                  </Card>
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 14 }}>
                  <Card
                    title="Analysis"
                    extra={
                      <>
                        <RangePicker
                          onChange={onAnalysisRangeChange}
                          presets={rangePresets}
                          className="custom-datepicker"
                          // disabledDate={disabledDate}
                          bordered={false}
                        />
                      </>
                    }
                    style={{ width: '100%' }}
                    className="dashboard-card">
                    <div className="chart-div">
                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Pie {...EinAnalyticsconfig} />
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Pie {...PlansAnalyticsconfig} />
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Pie {...SenFilesAnalyticsconfig} />
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Pie {...RecivedFilesAnalyticsconfig} />
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
export default withAuth(Dashboard);
